import React from "react"

import styles from "./cta.module.scss"

const CTA = (props) => (
  <section className={styles.container}>
    <ul className={styles.socialMediaList}>
      <li className={styles.socialItem}>
        <a className={styles.socialLink} href={props.facebook}>
          <i className={"fab fa-facebook fa-5x" + ' ' + styles.socialMedia}></i>
        </a>
      </li>
      <li className={styles.socialItem}>
        <a className={styles.socialLink} href={props.patreon}>
          <i className={"fab fa-patreon fa-5x" + ' ' + styles.socialMedia}></i>
        </a>
      </li>
      <li className={styles.socialItem}>
        <a className={styles.socialLink} href={props.discord}>
          <i className={"fab fa-discord fa-5x" + ' ' + styles.socialMedia}></i>
        </a>
      </li>
    </ul>
    <h2>Say your vows with us and man the wall because...</h2>
    <br /> <span>Winter is coming</span>

  </section>
)

export default CTA
