import React from "react"

import footerStyles from "./footer.module.scss"

const Footer = () => (
  <footer>
    Created by <a href="https://www.jamesnutter.dev">James Nutter</a>
    <br />
    &copy; 2021 Bend The Knee Podcast
  </footer>
)

export default Footer
