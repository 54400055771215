import React from "react"

import CTA from "./CTA/CTA"
import Footer from "./Footer/Footer"

import layoutStyles from "./layout.module.scss"

const Layout = props => (
  <div id="home">
    {props.children}
    <CTA
      patreon="https://www.patreon.com/bendtheknee"
      facebook="https://www.facebook.com/BTKcast"
      discord="https://discord.gg/Y2f4STG"
    />
    <div className={layoutStyles.backgroundFade}></div>
    <Footer />
  </div>
)

export default Layout
