import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'

import styles from './notFound.module.scss'


const NotFound = () => (
    <Layout>
        <div className={styles.container}>
            <h1>Page Not Found</h1>
            <p><Link to="/">Head home for the night is dark and full of terrors...</Link></p>
        </div>
    </Layout>
);

export default NotFound;
